import React from 'react'
import Room from '../Room'

const NutsList = ({nuts}) => {
  if (nuts.length === 0) {
    return (
      <div className='empty-search'>
        <h3>unfortunately no products matched your search</h3>
      </div>
    )
  }
  return (
    <section className='roomslist'>
      <div className='roomslist-center'>
       
        {nuts.map((room) => (
        <div key={room.id}>
        
          <Room key={room.id} room={room} />
        </div>
      ))}
        <div>
       
    </div>
      </div>
    </section>
  )
}

export default NutsList