import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import FlowerList from './FlowerList'

const Flower = () => {
  return (
    <RoomConsumer>
    {(value) => {
      const { loading, flower } = value
      
      if (loading) {
        return <Loading />
      }
      return (
        <div>
           <FlowerList flower={flower} />
        </div>
      )
    }}
  </RoomConsumer>
  )
}

export default Flower