import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import DatesList from './DatesList'

const Dates = () => {
  return (
    <RoomConsumer>
      {(value) => {
        const { loading, dates } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <DatesList  dates={dates} />
          </div>
        )
      }}
    </RoomConsumer>
  )
}

export default Dates