import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import LocustList from './LocustList'

const Locust = () => {
  return (
    <RoomConsumer>
    {(value) => {
      const { loading, locust } = value
      
      if (loading) {
        return <Loading />
      }
      return (
        <div>
           <LocustList locust={locust} />
        </div>
      )
    }}
  </RoomConsumer>
  )
}

export default Locust