import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import NutsList from './NutsList'

const Nuts = () => {
  return (
    <div>
        <RoomConsumer>
      {(value) => {
        const { loading, sortedRooms, rooms,nuts } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <NutsList nuts={nuts} />
          </div>
        )
      }}
    </RoomConsumer>
    </div>
  )
}

export default Nuts