import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import CornOilList from './CornOilList'

const CornOil = () => {
    return (
        <RoomConsumer>
        {(value) => {
          const { loading, cornOil } = value
          
          if (loading) {
            return <Loading />
          }
          return (
            <div>
               <CornOilList cornOil={cornOil} />
            </div>
          )
        }}
      </RoomConsumer>
      )
}

export default CornOil