import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import SugarList from './SugarList'

const Sugar = () => {
  return (
    <>
    <RoomConsumer>
    {(value) => {
      const { loading, sugar } = value
      
      if (loading) {
        return <Loading />
      }
      return (
        <div>
           <SugarList sugar={sugar} />
        </div>
      )
    }}
  </RoomConsumer>
    </>
  )
}

export default Sugar