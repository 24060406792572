import React from 'react'
import { HiOutlineMail, HiOutlineLocationMarker, HiOutlinePhone } from 'react-icons/hi';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const NavbarOne = () => {
  return (
    <>
     <div className="top-navbar">
      <div className="contact-info">
        <div className="contact-item">
          <HiOutlineMail className="contact-icon" />
          <span>sales@ankamarcgroup.com</span>
        </div>
        <div className="contact-item">
          <HiOutlineLocationMarker className="contact-icon" />
          <span>Ankara, Turkey</span>
        </div>
        <div className="contact-item">
          <HiOutlinePhone className="contact-icon" />
          {/* +905061258002 */}
          <span>+90 539 742 8550</span>
        </div>
      </div>
      <div className="quotation-container">
      <Link to="/quotation" className="quotation-btn" style={{ textDecoration: 'none' }}>Get a Quotation</Link>
        
      </div>
    </div>
    </>
  )
}

export default NavbarOne