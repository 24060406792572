import React from 'react'
import Room from '../Room'
const FoodList = ({food}) => {
    if (food.length === 0) {
        return (
          <div className='empty-search'>
            <h3>unfortunately no rooms matched your search</h3>
          </div>
        )
      }
      return (
        <section className='roomslist'>
          <div className='roomslist-center'>
           
            {food.map((room) => (
            <div key={room.id}>
            
              <Room key={room.id} room={room} />
            </div>
          ))}
            <div>
           
        </div>
          </div>
        </section>
      )
}

export default FoodList