import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import SteelList from './SteelList'

const Steel = () => {
  return (
    <div>
        <RoomConsumer>
      {(value) => {
        const { loading, steel } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <SteelList steel={steel} />
          </div>
        )
      }}
    </RoomConsumer>
    </div>
  )
}

export default Steel