import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import WoodList from './WoodList'


const WordPalete = () => {
  return (
    <div>
         <RoomConsumer>
      {(value) => {
        const { loading, wood } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <WoodList wood={wood} />
          </div>
        )
      }}
    </RoomConsumer>
    </div>
  )
}

export default WordPalete