import React,{useEffect} from 'react'
import Hero from '../components/Hero'
import Banner from '../components/Banner'
import { Link } from 'react-router-dom'
import RoomsContainer from '../components/RoomsContainer'
// import { Route } from 'react-router-dom';

const Rooms = () => {
  useEffect(() => {
    document.title = 'Product- Ankamark group'; // Set the desired page title here
  }, []);
  return (
    <>
      <Hero hero='roomsHero'>
        <Banner title='Our Products'>
          <Link to='/' className='btn-primary'>
            return Home
          </Link>
        </Banner>
      </Hero>
      {/* <Route exact path='/products/nuts' component={Nuts} /> */}
      <RoomsContainer />
    </>
  )
}

export default Rooms
