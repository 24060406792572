import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const QuotationForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [productName, setProductName] = useState("");
  const [quotationInfo, setQuotationInfo] = useState("");
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    // You can access the form values via the state variables (firstName, lastName, email, phone, productName, quotationInfo)
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          toast.success("Quotation received successfully");
        },
        (error) => {
          console.log(error);
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <div className="quotation-form">
        <h2>Quotation Request</h2>
        <form ref={form} onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="name-group">
              <div>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  // value={firstName}
                  // onChange={(e) => setFirstName(e.target.value)}
                  required
                  name="user_name"
                />
              </div>
              <div>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="user_email"
                  // value={lastName}
                  // onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="contact-group">
              <div>
                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div>
                <label htmlFor="phone">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  // value={phone}
                  // onChange={(e) => setPhone(e.target.value)}
                  name="user"
                  required
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="productName">Product Name</label>
            <input type="text" id="productName" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="quotationInfo">Quotation Information</label>
            <textarea id="quotationInfo" name="message" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  );
};

export default QuotationForm;
