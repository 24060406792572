import React from 'react'
import { RoomConsumer } from '../../context'
import Loading from '../Loading'
import DriedapricotList from './DriedapricotList'

const Driedapricot= () => {
  return (
    <RoomConsumer>
      {(value) => {
        const { loading, driedapricot } = value
        
        if (loading) {
          return <Loading />
        }
        return (
          <div>
             <DriedapricotList  driedapricot={driedapricot} />
          </div>
        )
      }}
    </RoomConsumer>
  )
}

export default Driedapricot