import React from 'react';
import Title from './Title';

const Welcome = () => {
  return (
    <div className="welcome">
      <Title title='WELCOME TO ANKAMARC GROUP' />
      <div className="description">
        <p>
          ANKAMARC GROUP offers services in Export/Import, distribution, and marketing expertise that help our customers make critical purchasing decisions and expand business within Turkey.
        </p>
        <p>
          Our company has spent numerous years (8) building relations with our customers to ensure that we remain the best with regards to our steady supply to our global buyers in the international market.
        </p>
        <p>
          ANKAMARC GROUP carries over 60 different types and variants of nuts, fruits, cereals, edible vegetable oil, spices, seasoners, and other products as found on our product list. All of our products are packaged by us with a dedicated staff ensuring we do not miss a beat. We also provide packaging solutions for retail brands throughout the country.
        </p>
      </div>
    </div>
  );
};

export default Welcome;
