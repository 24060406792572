import React,{useEffect,useRef } from "react";
import Hero from "../components/Hero";
import Banner from "../components/Banner";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import ContactForm from "../components/ContactForm";
import { Wrapper } from "@googlemaps/react-wrapper";
import emailjs from '@emailjs/browser';
import MapComponent from "../components/Map";

const location = {
  address: '1600 Amphitheatre Parkway, Mountain View, california.',
  lat: 37.42216,
  lng: -122.08427,
}

const About = () => {
  useEffect(() => {
    document.title = 'About Us Ankamark group'; // Set the desired page title here
  }, []);
  return (
    <>
      <Hero hero="roomsHero">
        <Banner title="About Us">
          <Link to="/" className="btn-primary">
            return Home
          </Link>
        </Banner>
      </Hero>
      <section className="single-room">
        <div className="single-room-info">
          {/* About us */}
          <article className="desc">
            <h3>About us</h3>
            <p>
              {" "}
              We are dedicated to providing the best quality products to our
              clients by partnering with the global market and looking for
              better partnerships in increasing our market share. With our long
              years of experience in the supplies of products all over the
              world, we have come to learn that costumers satisfaction is the
              number one priority and we have strived to keep that on for
              decades now with our ability to provide top quality products at
              the best possible prices. We strive to develop and maintain
              sustainable business relationships with suppliers and importers
              worldwide to provide the best quality products and services. We
              continue to develop new markets and bring new product lines to our
              new and existing clients. We pride ourselves on being a company
              bringing quality and values to our clients every day. We pride
              ourselves on our core values, ensuring that they are present in
              every interaction with clients on a day to day basis.{" "}
              <div></div>
              <br/>
             <b> Address: Gazi neighborhood Bozcaada street no:14 Yenimahalle Ankara</b>
            </p>
          </article>
          <article className="info">
            
            {/* contact us @ sales@ankamarcgroup.com */}
            <ContactForm />
          </article>
        </div>
      </section>
      
    {/* <MapComponent/> */}
    </>
  );
};

export default About;
