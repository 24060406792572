import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section">
        <h3>About Us</h3>
        <p>We are dedicated to providing the best quality products to our clients by partnering with 
          the global market and looking for better partnerships in increasing our market share.</p>
      </div>
      <div className="footer-section">
        <h3>Contact</h3>
        <p>Email: sales@ankamarcgroup.com</p>
        <p>Address: Gazi neighborhood Bozcaada street no:14 Yenimahalle, 06560, Ankara</p>
        <p>Phone:+90 539 742 8550</p>
      </div>
      <div className="footer-section">
        <h3>Follow Us</h3>
        <div className="social-icons">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="icon" />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="icon" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="icon" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
